/**
 * Binds space/enter keyboard controls to emulate a click, or trigger a function.
 * Designed to be the recipient of an onKeyUp event on a node/element.
 *
 * @param {Node} e - OnKeyUp event.
 */
const BindEnterAndSpaceToClick = e => {
	if (e.which === 32 || e.which === 13) {
		e.preventDefault();
		e.target.click();
	}
};

export default BindEnterAndSpaceToClick;
