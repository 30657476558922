import React from 'react';
import ImageLink from '../../../../../core/Components/ImageLink';

const MobileHeader = ({fields, showMobileMenu}) => {
	return (
		<div className={`mobile-header ${showMobileMenu ? 'is-hidden' : ''}`}>
			<span className="mobile-logo">
				<ImageLink imageField={fields.mobileLogo} linkField={fields.logoLink} />
			</span>
		</div>
	);
};

export default MobileHeader;
